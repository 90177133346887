.afg-scrollTop {
  position: fixed;
  bottom: 0;
  right: 0;
  transform: rotate(270deg);
  margin-right: 40px;
  margin-bottom: 40px;
  background-color: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 50%;
  border: 1px solid #111;

  @include media-breakpoint-down(sm){
    margin-right: 30px;
    margin-bottom: 30px;
  }
}