@import 'src/styles/variables';

footer {
  background-color: #111111;
  padding: 50px 0;
  color: white;
  text-align: center;

  .footer {
    margin: 0 auto;

    ul {
      padding-left: 0;

      li {
        list-style-type: none;

        a {
          color: $white;
        }
      }
    }
  }
}