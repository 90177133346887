:root {
  /* set default font size to 62.5% */
  /* this makes 1rem = 10px */
  font-size: 62.5%;
}

html {
  /* set default font size to 62.5% */
  /* this makes 1rem = 10px */
  font-size: 62.5%;
}