.afg-content {
  display: grid;
  grid-template-rows: 86px 1fr 115px;
  grid-template-columns: 1fr;
  height: 100%;
  background-color: #111;
}

.afg-header {
  background-color: #111111;
  padding: 10px 0;

  a {
    border-bottom: none;
    display: block;
    cursor: pointer;
    width: 100%;
    text-decoration: none;

    &:hover {
      border-bottom: none;
    }
  }

  &__logo {
    max-width: 230px;
    width: 100%;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  &__menu-btn {
    display: none;
    background-color: unset;
    outline: none;
    font-weight: 500;
    margin: 0 10px;
    padding: 12px 0;
    max-width: 40px;

    svg {
      width: 100%;
      height: auto;
    }

    svg path {
      &.line {
        fill: none;
        stroke: #fff;
        stroke-width: 6;
        transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &.line1 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }

      &.line2 {
        stroke-dasharray: 60 60;
        stroke-width: 6;
      }

      &.line3 {
        stroke-dasharray: 60 207;
        stroke-width: 6;
      }
    }

    &.active {
      .line1 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
      .line2 {
        stroke-dasharray: 1 60;
        stroke-dashoffset: -30;
        stroke-width: 6;
      }
      .line3 {
        stroke-dasharray: 90 207;
        stroke-dashoffset: -134;
        stroke-width: 6;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    display: flex;
    padding-left: 30px;

    span {
      padding: 12px 1.5em 12px 1em;
      color: #ffd900;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 0px;
    }

    li {
      padding-right: 3rem;

      &:last-child {
        padding-right: 0px;
      }

      a {
        color: #fff;
        font-weight: 500;
        padding: 12px 0 5px;

        &:hover {
          text-decoration: none;
          -webkit-box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
          -moz-box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
          box-shadow: 0px 1px 0px 0px rgba(255,255,255,1);
        }
      }
    }
  }

  @include media-breakpoint-down(sm){
    display: flex;
    flex-direction: column;

    &__logo {
      max-width: 150px;
    }

    &__menu-btn {
      align-items: center;
      display: flex;
    }

    &__list {
      position: absolute;
      top: 73px;
      left: 0;
      right: 0;
      background-color: #111111;
      z-index: 500;
      height: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-in-out;
      display: block;
      width: 100%;

      &.active {
        height: calc(100vh - 64px);
        opacity: 1;
        visibility: visible;
      }

      span {
        padding-left: 0;
        padding-right: 0;
      }

      ul {
        margin-top: 1em;
        display: block;
      }

      li {
        padding: 10px 0;
      }

      li a {
        border-bottom: none;
        font-size: 18px;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}