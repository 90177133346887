/* Typography settings */

// Styles for:
// h1, h2, h3, h4, h5, blockquote, q, ul, ol, p

h1, h2, h3, h4, h6, .afg-h1, .afg-h2 {
  font-family: 'Teko', sans-serif;
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}

h1, .afg-h1 {
  font-size: 3.2rem;
}

h2, .afg-h2 {
  font-size: 2.4rem;
}

h3, .afg-h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem;
  line-height: 1.4;
}

h5 {
  font-size: 1.4rem;
  line-height: 1.57;
  letter-spacing: 0.02px;
}

h6 {
  font-size: 1.2rem;
  line-height: normal;
  letter-spacing: 0.02px;
}

p, li, label, a {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  color: #fff;
}

a {
  font-weight: bold;
  text-decoration: underline;
}

label {
  font-size: 1.6rem;
  line-height: 1.29;

  a {
    font-size: 1.6rem;
    line-height: 1.29;
  }
}

@include media-breakpoint-up(sm) {
  h1, h2, h3, .afg-h1 {
    letter-spacing: 0;
    line-height: 1.15;
  }

  h1, .afg-h1 {
    font-size: 4.8rem;
  }

  h2, .afg-h2 {
    font-size: 3.2rem;
  }

  h3, .afg-h3 {
    font-size: 2.4rem;
  }

  h4 {
    font-size: 2rem;
    letter-spacing: normal;
    line-height: 1.44;
  }

  h5 {
    font-size: 1.8rem;
    letter-spacing: 0.03px;
    line-height: 1.22;
  }

  h6 {
    font-size: 1.4rem;
    line-height: normal;
    text-transform: uppercase;
  }
}

.afg-gallery {
  img {
    height: 375px;
    object-fit: cover;
    object-position: center;
  }

  @include media-breakpoint-down(sm) {
    height: auto;
    object-fit: unset;
  }
}
